import { createReducer, on } from '@ngrx/store';
import * as actions from './funds.actions';

import { FundResponse } from '@root/api/idlt';
import { FundsMap } from '@funds/services/funds-api.service';


export interface FundState {
  funds: FundResponse[] | null;
  fundsById: any;
  getFundsError: string | null;
  getFundsInprogress: boolean;
  getFundsSuccess: boolean;
  getFundsComplete: boolean;
}


export const fundsFeatureKey = 'funds';

export const initialState: FundState = {
  funds: null,
  fundsById: null,
  getFundsError: null,
  getFundsInprogress: false,
  getFundsSuccess: false,
  getFundsComplete: false,
};

export const fundsReducer = createReducer(
  initialState,
  on(actions.getFunds, state => {
    return {
      ...state,
      getFundsError: null,
      getFundsInprogress: true,
      getFundsSuccess: false,
      getFundsComplete: false,
    }
  }),
  on(actions.getFundsSuccess, (state, { funds, fundsById, getFundsComplete }) => {
    let _funds: FundResponse[] = [];
    let _fundsById: FundsMap = {};

    if(state.funds && state.funds.length) { _funds = [...state.funds] };
    if(state.fundsById) { _fundsById = {...state.fundsById} };

    return {
      ...state,
      funds: [ ..._funds, ...funds ],
      fundsById: { ..._fundsById, ...fundsById },
      getFundsError: null,
      getFundsInprogress: false,
      getFundsSuccess: true,
      getFundsComplete,
    }
  }),
  on(actions.getFundsError, (state, { error }) => {
    return {
      ...state,
      funds: null,
      fundsById: null,
      getFundsError: error,
      getFundsInprogress: false,
      getFundsSuccess: false,
      getFundsComplete: true,
    }
  }),
  on(actions.removeFunds, state => {
    return {
      ...state,
      funds: null,
      fundsById: null,
    }
  })
);