import { createAction, props } from '@ngrx/store';
import { FundResponse } from '@root/api/idlt';


/**
 * Get Funds
 */

export const GET_FUNDS = '[Funds] Get Funds';
export const getFunds = createAction(
  GET_FUNDS,
  props<{ 
    pageSize: number; 
    page: number; 
  }>()
);

export const GET_FUNDS_SUCCESS = '[Funds] Get Funds Success';
export const getFundsSuccess = createAction(
  GET_FUNDS_SUCCESS,
  props<{ 
    funds: FundResponse[]; 
    fundsById: any; 
    getFundsComplete: boolean;
  }>()
);

export const GET_FUNDS_ERROR = '[Funds] Get Funds Error';
export const getFundsError = createAction(
  GET_FUNDS_ERROR,
  props<{ 
    error: string; 
  }>()
);

export const REMOVE_FUNDS = '[Funds] Remove Funds';
export const removeFunds = createAction(
  REMOVE_FUNDS
);

