import { ShareClassGetResponse, Subscription } from "@root/api/idlt";

export const FUNDS_FORMAT_DATE = 'MM-dd-yyyy';
export const DEFAULT_LOCALE = 'en-US';
export const FORMAT_NULL_VALUE_REPLACE = '-';
export const GENII_ID_EXTERNAL_TYPE = 'Gen II Fund ID';
export const ICAP_ID_EXTERNAL_TYPES = [ 'PAF_ID', 'ICN_PAF_ID' ];
export const INVESTMENT_ID_EXTERNAL_TYPE = 'ICN_INVESTMENT_ID';
export const ICN_INVESTOR_PROFILE_ID_TYPE = 'ICN_INVESTOR_PROFILE_ID';
export const GDM_SHARE_CLASS_ID_TYPE = 'GDM_SHARE_CLASS_ID';
export const MAT_SPINNER_DIAMETER = 40;
export const APP_NAME = 'Gen II DLT Connect';
export const APP_DEFAULT_TITLE = '';
export const APP_DEFAULT_ICON = 'menu';
export const DEFAULT_QUERY_PAGE_SIZE = 1000;
export const DEFAULT_QUERY_PAGINATION_SIZE = 20;
export const DEFAULT_SORT_BY = 'id';
export const DEFAULT_SORT_DESCENDING = true;
export const DEFAULT_SNACKBAR_DURATION = 3000;
export const CAPITAL_EVENT_CAPITAL_CALL = 'Capital Call';
export const CAPITAL_EVENT_CAPITAL_DISTRIBUTION = 'Distribution';
export const CAPITAL_EVENT_CAPITAL_NET_CALL = 'Net Capital Call';
export const CAPITAL_EVENT_CAPITAL_NET_DISTRIBUTION = 'Net Distribution';
export const CAPITAL_EVENT_STATUS: string[] = ['Canceled','Archived','PendingValidation','ActionRequired','PendingPublish','AwaitingGPApproval','AwaitingStatements','AwaitingCapital','AwaitingDistribution','AwaitingWireApproval','Complete'];

enum StatusCode {
  CANCELLED = 'Canceled',
  ARCHIVED = 'Archived',
  PENDING_VALIDATION = 'PendingValidation',
  ACTION_REQUIRED = 'ActionRequired',
  PENDING_PUBLISH = 'PendingPublish',
  AWAITING_GP_APPROVAL = 'AwaitingGPApproval',
  AWAITING_STATEMENTS = 'AwaitingStatements',
  AWAITING_CAPITAL = 'AwaitingCapital',
  AWAITING_DISTRIBUTION = 'AwaitingDistribution',
  AWAITING_WIRE_APPROVAL = 'AwaitingWireApproval',
  COMPLETE = 'Complete'
}

export function getCapitalEventStatusMessage(status: StatusCode): string {
  const statusMessages: { [key in StatusCode]: string } = {
    [StatusCode.CANCELLED]: 'Cancelled',
    [StatusCode.ARCHIVED]: 'Archived',
    [StatusCode.PENDING_VALIDATION]: 'Pending Validation',
    [StatusCode.ACTION_REQUIRED]: 'Action Required',
    [StatusCode.PENDING_PUBLISH]: 'Pending Publish',
    [StatusCode.AWAITING_GP_APPROVAL]: 'Awaiting GP Approval',
    [StatusCode.AWAITING_STATEMENTS]: 'Awaiting Statements',
    [StatusCode.AWAITING_CAPITAL]: 'Awaiting Capital',
    [StatusCode.AWAITING_DISTRIBUTION]: 'Awaiting Distribution',
    [StatusCode.AWAITING_WIRE_APPROVAL]: 'Awaiting Wire Approval',
    [StatusCode.COMPLETE]: 'Complete'
  };
  return statusMessages[status] || 'Unknown Status';
}

export enum CapitalEventPostStatus {
  INIT = 'INIT',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESFULLY = 'SUCCESFULLY',
  FAILED = 'FAILED'
}

export interface SubscriptionData extends Subscription {
  shareClass?: ShareClassGetResponse;
}